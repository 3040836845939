type Weight = {
	readonly g: number;
	readonly kg: number;
	readonly lb: number;
};

const kgToPounds = 2.20462;
const gToPounds = kgToPounds / 1_000.0;

function weightFromKg(kg: number): Weight {
	return {
		kg,
		g: kg * 1000,
		lb: kg * kgToPounds,
	};
}

function weightFromG(g: number): Weight {
	return {
		kg: g / 1000,
		g,
		lb: g * gToPounds,
	};
}

export type { Weight };
export { weightFromKg, weightFromG };
